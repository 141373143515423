<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<div class="mt-10 text-right category bg-white pa-10 box">

			<template

				v-for="(item, index) in category_list"
			>
				<button
					v-if="item.exposure == 1"
					:key="'category_' + index"
					:value="item.idx"
					class="pa-5-10 cursor-pointer mr-10 box radius-10"
					:class="search.categoryIdx == item.idx ? 'bg-identify' : ''"
					@click="search.categoryIdx = item.idx; getSearch(1)"
				>{{ item.name }}</button>
			</template>

			<button
				class=" bg-green-outline mr-10"
				style="padding: 0px 3px 3px 3px"
				@click="toSave"
			><v-icon small class="color-green">mdi mdi-plus</v-icon></button>
			<button
				class="bg-identify-outline mr-10"
				style="padding: 0px 3px 3px 3px"
				@click="toSetting"
			><v-icon small class="color-identify">mdi mdi-cog</v-icon></button>
			<button
				class="bg-green pa-5-10"
				@click="toItem"
			>등록</button>
		</div>

		<div class="bg-white mt-10 pa-10 height-8 flex-column overflow-y-auto ">
			<div class="justify-space-between">


				<div v-if="false" class="line-height-200">총 <span class="font-weight-bold">{{ search.tCnt | makeComma }}</span> 건</div>
				<div></div>
				<select
					class="pa-5-10 mr-10 size-px-12"
					v-model="search.size"
					@change="getSearch()"
				>
					<option
						v-for="cnt in codes.list_cnt"
						:key="'cnt_' + cnt"
						:value="cnt"
					>{{ cnt }} 건씩 보기</option>
				</select>
			</div>

			<table
				v-if="items.length > 0"
				class="table table-even top-line-identify mt-10"
			>
				<colgroup>
					<col width="80px" />
					<col width="auto" />
					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>제목</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{ item.idx }}</td>
					<td class="text-left">{{ item.title }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center top-line-identify mt-10"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			@close="clear"
			@click="saveCategory"
			@cancel="clear"
		>
			<div slot="modal-content">
				<table class="table ">
					<tbody>
					<tr>
						<th>카테고리명</th>
						<td><input v-model="item_category.categoryName" class="input-box" /></td>
					</tr>
					</tbody>
				</table>
			</div>
		</Modal>

		<Modal
			:is_modal="is_modal2"
			:option="modal_option2"
			bottom_class="justify-center"

			@close="clear"
			@click="updateCategory"
			@cancel="clear"
		>
			<FaqCategory
				slot="modal-content"
				:items="items_category"

				@setNotify="setNotify"
			></FaqCategory>
		</Modal>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Modal from "@/components/Modal";
import FaqCategory from "@/view/Board/FaqCategory";

export default {
	name: 'SampleList',
	components: {FaqCategory, Modal, Pagination },
	data: function(){
		return {
			program: {
				name: 'FAQ 문의'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,search_value: this.$route.query.search_value ? this.$route.query.search_value : ''
				, categoryIdx: this.$route.query.category ? this.$route.query.category : 1
                ,total_count: 0
			}
			,search_option: {

			}
			,items: []
			,item_category: {}
			,is_modal: false
			,modal_option: {
				title: 'FAQ 카테고리 추가'
				,top: true
				,bottom: true
				,width: '360px'
			}
			,is_modal2: false
			,modal_option2: {
				title: 'FAQ 카테고리 설정'
				,top: true
				,bottom: true
			}
			,items_category: []
		}
	}
	,computed: {
		item_list: function(){
			return this.items.filter(function(item){
				return item
			})
		}
		,category_list: function(){
			return this.items_category.filter(function(item){
				return item
			})
		}
		,item_input: function(){
			let items = []
			this.items_category.filter(function(item){

				let item2 = []
				item2['idx'] = item.idx
				item2['exposure'] = !item.exposure ? 0: item.exposure
				item2['isDelete'] = !item.is_delete ? 0 : 1

				items.push(item2)
			})

			return items
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'faq/list/' + this.search.categoryIdx
				})

				if(result.success){
					this.items = result.data
                    this.search.total_count = result.data.totalCount
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getCategory: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'faq/category/set'
				})

				if(result.success){
					this.items_category = result.data
					if(!this.$route.query.category){
						//this.$layout.push( { name: this.$route.name, query: { category: this.items_category[0].idx }})
						this.search.categoryIdx = this.items_category[0].idx
						this.getData()
					}
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,saveCategory: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'post'
					,url: 'faq/category'
					,data: this.item_category
				})

				if(result.success){
					this.$layout.setNotify( { type: 'success', message: result.message})
					this.clear()
					await this.getCategory()
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,updateCategory: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'post'
					,url: 'faq/category/set'
					,data: {
						faqCategoryList: this.item_input
					}
				})

				if(result.success){
					this.$layout.setNotify( { type: 'success', message: result.message})
					this.clear()
					await this.getCategory()
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
			}finally {
				this.$layout.offLoading()
			}
		}
		,toDetail: function(item){
			this.$layout.push( { name: 'FaqDetail', params: {idx: item.idx}})
		}
		,getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}

		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,push: function({ name, query}){
			this.$layout.push( { name: name, query: query})
		}
		,toItem: function(){
			this.$layout.push( {name: 'FaqItem'})
		}
		,clear: function(){
			this.is_modal = false
			this.is_modal2 = false
		}
		,toSave: function(){
			this.is_modal = true
		}
		,toSetting: function(){
			this.is_modal2 = true
		}
		,setNotify: function({ type, message}){
			this.$layout.setNotify( { type: type, message: message })
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.$layout.onLoad(this.program)
		this.getCategory()
	}
	,watch: {
		'$route.query.category':{
			immediate: true
			,handler: function(call) {
				if(call) {
					this.getData()
				}
			}
		}
	}
}
</script>

<style>
.category .on { font-weight: bold; border: 1px solid #ddd; border-radius: 10px; background-color: #eee}
</style>